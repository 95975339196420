import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import betsyJustin7 from "../images/betsyJustin7.jpg";
import betsyJustin6 from "../images/betsyJustin6.jpg";
import betsyjustin4 from "../images/betsyjustin4.jpg";
import nashville from "../images/nashville.jpg";


function IndexPage() {
  return (
    <Layout>
      <SEO
        title="Home"
        description="Wedding Website for Betsy and Justin"
        keywords={[`The Royal Tannenbaums`, `Anna Elizabeth Appleton`, `Justin Edward Tannenbaum`, `Betsy and Justin`]}
      />
      <div>
        <img
          src={betsyJustin7} 
          class="z-0 flex-grow object-cover h-screen w-screen" 
          alt="Justin and Betsy" 
        />
        <div class="max-w-full">
          <div class="relative">
              <div class="relative lg:flex overflow-hidden">
                  <div class="h-screen lg:h-auto lg:w-6/12 relative flex items-center justify-center">
                      <img 
                        class="absolute h-full w-full object-cover" 
                        src={betsyjustin4}
                        alt="Betsy and Justin" 
                      />
                  </div>
                  <div id="our-story" class="relative lg:w-6/12 bg-white h-screen">
                    <div class="font-sans-serif relative py-12 lg:py-24 px-8 lg:px-16 text-black leading-relaxed">
                        <p class="mb-6 text-2xl">
                            OUR LOVE STORY
                        </p>
                        <br></br>
                        <p>
                          Let me take you back in time and set the stage on how long and arduous this courtship was.
                          (That’s not a dig, it was totally worth the wait!) It’s April 2011 in Knoxville, TN, and I’m living up my last month of college before I move to Nashville to pursue my life-long dream of becoming an attorney.
                          One of my roommates (and bridesmaids) was attending a fraternity date party and asked me to come along because #buddysystem.
                          I reluctantly agreed after stalking his Facebook (pre-Instagram days). 
                          Despite having polar opposite upbringings, conversation flowed effortlessly, and we had an instant connection.
                          I wouldn’t say it was “love at first sight”, but we were definitely intrigued by each other.
                        </p>
                        <br></br>
                        <p>
                          A month after our first meeting, I moved to Nashville to attend law school at Belmont while Justin finished up undergrad in Knoxville.
                          Though we stayed in contact, Justin dragged his feet (admittedly) in pursuing anything further than a casual relationship.
                          Due to my compelling power of persuasion and a job opportunity, Justin moved to Nashville in the fall of 2012.
                          On January 31, 2013, nearly two years after our first meeting, Justin FINALLY asked me to be his girlfriend.
                          I should have known then that he would take his sweet time to pop the question, but I’m so glad he did!
                        </p>
                        <br></br>
                        <p>
                          Over the six years of dating, we dined at nearly every Nashville food establishment,
                          enjoyed countless concerts, traveled, championed each other’s careers, and supported one another through loss and heartache.
                          It was nowhere near perfect, but we each grew independently as well as together as a couple. 
                        </p>
                    </div>
                  </div>
              </div>
          </div>
        </div>
        <div class="">
          <img
            src={betsyJustin6} 
            class="z-0 object-cover h-screen w-screen bg-local" 
            alt="Justin and Betsy" 
          />
        </div>
        <div id="details" class="max-w-full">
          <div class="relative">
              <div class="relative lg:flex overflow-hidden">
                  <div class="relative lg:w-6/12 bg-white">
                    <div class="font-sans-serif relative py-12 lg:py-24 px-8 lg:px-16 text-black leading-relaxed">
                        <p class="mb-6 text-2xl">
                            WEDDING DETAILS
                        </p>
                        <br></br>
                        <p class="text-xs mb-2 font-bold text-black">
                          WHEN
                        </p>
                        <p>
                          Saturday, August 28, 2021 at 6:00 PM
                        </p>
                        <br></br>
                        <p class="text-xs mb-2 font-bold text-black">
                          <b>WHERE</b>
                        </p>
                        <p class="text-blue-600 underline">
                          <a href="https://goo.gl/maps/WzwXTUzJFnb8bH297">Tennessee State Museum - 1000 Rosa L. Parks Blvd. Nashville, TN 37208</a>
                        </p>
                        <br></br>
                        <p class="text-xs mb-2 font-bold text-black">
                          <b>ATTIRE</b>
                        </p>
                        <p>
                          Black Tie Optional
                        </p>
                        <br></br>
                        <p class="text-xs mb-2 font-bold text-black">
                          <b>TRANSPORTATION</b>
                        </p>
                        <p>
                          Ample parking is provided at the wedding venue. For out of town guests, we encourage the use of a ride share service, Uber or Lyft.
                        </p>
                        <br></br>
                        <p class="text-xs mb-2 font-bold text-black">
                          <b>A NOTE FROM THE BRIDE AND GROOM</b>
                        </p>
                        <p>
                          We can’t wait to tie the knot in front of our favorite people! 
                          We hope that you can enjoy this special moment with us phone free, so please, no cell phones nor photos until our ceremony conclusion.
                          <br></br><br></br>
                          To allow all of our guests the chance to let their hair down, this is an adults-only celebration. Thank you for your understanding!
                        </p>
                        <br></br>
                    </div>
                  </div>
                  <div class="h-screen lg:h-auto lg:w-6/12 relative flex items-center justify-center">
                    <img 
                      class="absolute h-full w-full object-cover" 
                      src={nashville} 
                      alt="lower broadway nashville, tn" 
                    />
                  </div>
              </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default IndexPage;
